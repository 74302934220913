<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                :title="type_title"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <vrow v-for="index in size" :key="index" gutter="20" type="flex" justify="center" style="margin-top: 15px">
            <vcol span="12">
                <div align="center">
                    <div v-if="(index-1)*2 <total">
                        <vimg
                                :src="picUrl+exes[(index-1)*2].cover"
                                @click="showExe(exes[(index-1)*2].id)"
                        />
                        <div class="text">{{exes[(index-1)*2].name}}</div>
                    </div>
                </div>
            </vcol>
            <vcol span="12">
                <div align="center">
                    <div v-if="(index-1)*2+1 <total">
                        <div class="text">{{exes[(index-1)*2+1].note}}</div>
                        <vimg
                                :src="picUrl+exes[(index-1)*2].cover"
                                @click="showExe(exes[(index-1)*2+1].id)"
                        />
                        <div class="text">{{exes[(index-1)*2+1].name}}</div>
                    </div>
                </div>
            </vcol>
        </vrow>
        <br>
        <br>
        <RLFooter/>
    </div>
</template>

<script>

    import {Image as vimg,Col as vcol, Row as vrow} from 'vant';
    import {getFollows} from '@/api/follow';
    import {getExerciseType} from '@/api/jkgl';
    // import {setToken,removeToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'jkgl',
        components: {
            vimg,vcol,vrow
        },
        data(){
            return {
                user:{
                    name:'',
                    idCard:'',
                    telephone:'',
                    gender:'1',
                    birthday:'',
                    address:'',
                    detail_address:'',
                    id:''
                },
                total:0,
                size:0,
                nodata:false,
                picUrl:'https://guanjietong.rilintech.com/',
                activeName:'-1',
                activeNameBw:'1',
                exes:[],
                type_title:''
            };
        },
        computed:{

        },
        methods:{
            toExerciseType(type_code){
                console.log(type_code)
                this.$router.push({path: '/jkgl_type/',query:{type_code:type_code}});

            },
            initidx(){
                console.info(this.$store.state.user);
                //this.$store.commit('updatePid',this.$route.params.pid);
                let query = this.$route.query;

                let follow_time=query.follow_time?query.follow_time : this.parseTime(new Date(),'{y}-{m}-{d}');
                getFollows({follow_time:follow_time,pid:this.$route.params.pid}).then((response) => {
                    if(response.code==200 ){
                        // this.loading=true;
                        this.active=response.data.total;

                        this.followcrfs=response.data.rows;
                        console.log(response.data)

                        if(this.followcrfs.length==0){
                            this.nodata = true
                        }
                    }
                });
                // getqueidxs({pid:this.$route.params.pid}).then((response) => {
                //     this.loading=true;
                //     if(this.followcrfs.length==0){
                //         this.followcrfs=response.data;
                //     }
                // });
            },
            showExe(send_id){
                this.$router.push({path: '/exe/'+send_id});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            initData(){
                let query = this.$route.query;
                let type_code=query.type_code;
                this.type_title=query.type_title;
                // if(this.follow_time == undefined){
                //     this.follow_time = this.parseTime(new Date(),'{y}-{m}-{d}');
                // }
                getExerciseType({type_code:type_code,patientid:this.user.id}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        this.exes = response.data.exes;

                        this.total = response.data.exes.length
                        this.size = Math.ceil(this.total/2)
                    }

                });
            },
        }
        ,
        created() {
            //生产环境
            // var query = this.$route.query;
            // if(query.code || this.getQueryString('code')){
            //     // console.log("code")
            //     // console.log(query.code)
            //     // console.log(this.getQueryString('code'))
            //     //this.$store.commit('updateOpenid','123456');
            //     this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
            //     setToken(query.code?query.code : this.getQueryString('code'));
            //     getUser().then((res) => {
            //         console.info(res.data);
            //
            //         if(res.data){
            //             this.$store.commit('updateOpenid',res.data.openid);
            //             this.$store.commit('updatePid',res.data.id);
            //             this.$store.commit('updateBaseInfo',res.data);
            //             if(res.data.name !=''){
            //                 this.substatus = true
            //             }
            //             this.user.id=res.data.id;
            //             this.user.name=res.data.name;
            //             this.user.birthday=res.data.birthday;
            //             // this.user.idCard=response.data.idCard;
            //             this.user.telephone=res.data.telephone;
            //             this.user.gender=res.data.gender+'';
            //             this.user.address=res.data.address;
            //             this.user.detail_address=res.data.detail_address;
            //             // this.initBaseInfo();
            //
            //             this.initData()
            //
            //         }
            //     });
            //     //getBaseInfo({}).then((response) => {
            //     //   console.info(response);
            //     // });
            // }else{
            //     removeToken();
            // }
            // 开发环境
            getUser().then((res) => {
                console.info(res.data);

                if(res.data){
                    this.$store.commit('updateOpenid',res.data.openid);
                    this.$store.commit('updatePid',res.data.id);
                    this.$store.commit('updateBaseInfo',res.data);
                    if(res.data.name !=''){
                        this.substatus = true
                    }
                    this.user.id=res.data.id;
                    this.user.name=res.data.name;
                    this.user.birthday=res.data.birthday;
                    // this.user.idCard=response.data.idCard;
                    this.user.telephone=res.data.telephone;
                    this.user.gender=res.data.gender+'';
                    this.user.address=res.data.address;
                    this.user.detail_address=res.data.detail_address;
                    // this.initBaseInfo();

                    this.initData()

                }
            });
        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
